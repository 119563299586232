import { render, staticRenderFns } from "./Callback.html?vue&type=template&id=423d88ff&external"
import script from "./Callback.ts?vue&type=script&lang=js&external"
export * from "./Callback.ts?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./Callback.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fcallback%2FCallback.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports
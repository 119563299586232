import { OdataItems } from '@/shared/model/OdataItems';
import { Organisation } from '@/shared/model/organisation';
import { Component, Vue } from 'vue-property-decorator';
import { Action, namespace } from 'vuex-class';
import { Logger } from 'fsts';
import { AxiosPromise } from 'axios';

const logger = new Logger('callback');
const authModule = namespace('auth');
const organizationModule = namespace('organization');

@Component({
  components: {},
})
export default class CallbackView extends Vue {
  @Action('load_info')
  private loadInfo!: any;
  @authModule.Action('signinRedirectCallback')
  private signinRedirectCallback!: any;
  @authModule.Action('getUser')
  private getUser!: any;
  @authModule.Mutation('setToken')
  private setToken!: any;
  @organizationModule.Mutation('setOrganization')
  private mutationSetOrganization!: any;
  @organizationModule.Getter('getOrganizations')
  private organizations!: OdataItems<Organisation>;

  //load init data after login (sinchronized not need)
  @organizationModule.Action('getOrganizationsAll')
  private getOrganizationsAll!: () => AxiosPromise<Organisation>;

  @authModule.Getter('isSiteOwner')
  private isSiteOwner!: boolean;

  created() {
    try {
      //if cancel login operation then return to home path
      if (this.$route.hash.indexOf('error=access_denied') > 0) this.$router.push({ path: '/' });

      this.signinRedirectCallback().then(async (account: any) => {
        if (account.access_token === undefined) {
          this.$router.push({ path: '/' });
          return;
        }
        this.setToken(account);
        await this.getOrganizationsAll();
        this.loadInfo();
        //after loading user info. select language selected by user
        this.$root.$i18n.locale = account?.profile?.language;
        this.$vuetify.lang.current = account?.profile?.language;
        // select theme
        this.$vuetify.theme.dark = account?.profile?.theme == 'dark';
        //set as main organization
        this.mutationSetOrganization({ id: account?.profile?.current_organization_id });
        this.$backendHub.startSignalR(account.access_token);
        this.$backendHub.addressOpened(account?.profile?.sub);
        this.$backendHub.OrgJoin(account?.profile?.current_organization_id);

        let returnToUrl = `/dashboard/organization/${account?.profile?.current_organization_id}`;
        if (account?.profile?.is_site_owner == 'True') {
          returnToUrl = '/superAdmin/admin';
        }
        if (account?.state !== undefined) {
          returnToUrl = account.state;
        }
        this.$router.push({ path: returnToUrl }).catch(() => {});
      });
    } catch (e) {
      logger.error(e);
      // this.$router.push({ name: 'Unauthorized' });
    }
  }
}
